import { render, staticRenderFns } from "./TicketsHistory.vue?vue&type=template&id=79f4335d&scoped=true"
import script from "./TicketsHistory.vue?vue&type=script&lang=js"
export * from "./TicketsHistory.vue?vue&type=script&lang=js"
import style0 from "./TicketsHistory.vue?vue&type=style&index=0&id=79f4335d&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f4335d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79f4335d')) {
      api.createRecord('79f4335d', component.options)
    } else {
      api.reload('79f4335d', component.options)
    }
    module.hot.accept("./TicketsHistory.vue?vue&type=template&id=79f4335d&scoped=true", function () {
      api.rerender('79f4335d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TicketsHistory.vue"
export default component.exports